<template>
  <el-main class="main">
    <div class="title">海外知识产权动态及要点信息</div>
    <div class="sep" />
    <div class="content">
      <div
        class="row"
        v-for="info in infoList"
        :key="info.index"
        @click="handleClick(info.url)"
      >
        <span>{{ info.title }}</span>
      </div>
    </div>
  </el-main>
</template>

<script>
import store from "@/store";

export default {
  name: "info",
  data() {
    return {
      infoList: [
        {
          index: 0,
          title:
            "欧盟知识产权局就商标申诉程序中的证据问题发布共同实践做法 - 海外知识产权动态信息 - 智南针 (worldip.cn)",
          url: "https://worldip.cn/",
        },
        {
          index: 1,
          title:
            "澳大利亚外观设计法修正案生效 - 海外知识产权动态信息 - 智南针 (worldip.cn)",
          url: "https://worldip.cn/",
        },
        {
          index: 2,
          title:
            "韩国专利法修正案保障申请人权利 - 海外知识产权动态信息 - 智南针 (worldip.cn)",
          url: "https://worldip.cn/",
        },
        {
          index: 3,
          title:
            "日本专利局发布中小企业知识产权行动指南 - 海外知识产权动态信息 - 智南针 (worldip.cn)",
          url: "https://worldip.cn/",
        },
        {
          index: 4,
          title:
            "预警：中国企业应加强海外商业秘密纠纷风险防控 - 全部 - 智南针 (worldip.cn)",
          url: "https://worldip.cn/",
        },
        {
          index: 5,
          title:
            "天价赔偿！解析摩托罗拉海能达案的陪审团裁决及实践经验 - 全部 - 智南针 (worldip.cn)",
          url: "https://worldip.cn/",
        },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    store.commit("service/SET_ACTIVEINDEX", { activeIndex: "3" });
    next();
  },
  methods: {
    handleClick(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="stylus" scoped>
.main {
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 72px;

  .title {
    font-size: 30px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: #2C53B0;
    line-height: 42px;
    text-align: center;
  }

  .sep {
    height: 2px;
    background: #2C53B0;
    margin-top: 14px;
  }

  .content {
    padding-top: 12px;
    padding-bottom: 100px;

    .row {
      padding: 13px 21px 13px 21px;
      border-bottom: 1px solid #F3F5FB;
      word-break: break-all;
      &:hover {
        background-color: #F3F5FB;
        cursor: pointer;

        span {
          color: #2C53B0;
          font-weight: bold;
        }
      }

      span {
        font-size: 16px;
        font-family: PingFang-SC;
        font-weight: 400;
        color: #222222;
        line-height: 24px;
      }
    }
  }
}
</style>